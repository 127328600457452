import route from "../router/constants"

export default {

    props: {transitionEntered: Boolean},
    methods: {
        getLinkTo() {
            switch (true) {
                case this.$route.params.id < 0 :
                    // show main video
                    return {name: route.WATCH_MAIN}
                case this.$route.params.id > 0:
                    // show trailer
                    return {name: route.WATCH, params: {id: this.$route.params.id}}
                default:
                    //back home
                    return {name: route.HOME}
            }
        },

        closeOverlay() {
            this.$router.push(this.getLinkTo())
        }
    }
}
