<template>
    <div class="form">
        <transition name="slide-in">
            <div v-if="success" key="send">
                <div class="form__message form__message--success">
                    {{ form.confirmations[Object.keys(form.confirmations)[0]].message }}
                </div>
                <div v-if="!!$slots.default" class="form__footer">
                    <slot/>
                </div>
            </div>
        </transition>
        <transition name="opacity" mode="in-out">
            <form v-show="!success" class="form__form" @submit.prevent="formSubmit" key="form">
                <div v-for="(field, key) in fields" class="form__field" :key="'input-'+field.id"
                     :class="{
                    'focused': active_field == key,
                    'filled': formData[inputName(field.id)],
                    'error': validation_messages[field.id]
                     }">
                    <template v-if="field.type=='checkbox'">
                        <div v-for="(input, index) in field.inputs" :key="input.id"
                             :class="`form__input-${field.type}`">
                            <input :type="field.type" :name="'input_'+input.id" :id="'input_'+input.id"
                                   @change="clearError(field.id)"
                                   v-model="formData[inputName(input.id)]" :true-value="field.choices[index].value">
                            <label :for="'input_'+input.id" class="form__label"
                                   :class="formData[inputName(input.id)]? 'checked':''">{{ input.label }}</label>
                        </div>
                    </template>
                    <template v-else-if="field.type=='consent'">
                        <div v-if="field.inputs.length" class="form__container"
                             :class="`form__container-${field.type}`">
                            <input v-for="(input, index) in field.inputs" :key="input.id"
                                   :type="input.isHidden?'hidden':'checkbox'" :name="'input_'+input.id"
                                   :id="'input_'+input.id"
                                   @change="clearError(field.id)"
                                   v-model="formData[inputName(input.id)]" :true-value="(index==1)?field.label:index+1">
                            <label :for="'input_'+field.inputs[0].id" class="form__container"
                                   :class="formData[inputName(field.id)]? 'checked':''">
                            <span :set="input=field.inputs[0]" class="form__checkbox-placer">
                            <icon-checkmark v-if="formData[inputName(input.id)]"/></span>
                                <link-privacy-replace class="form__field-label" :class-name="'underline'"
                                                      :html="field.description"/>
                            </label>
                        </div>
                    </template>
                    <template v-else>
                        <select v-if="field.type=='select'" v-model="formData[inputName(field.id)]"
                                @change="clearError(field.id)"
                                @focus="inputFocus(key)"
                                @blur="inputBlur(key)" class="form__input">
                            <option v-for="choice in field.choices" :key='choice.value?choice.value:choice.name'
                                    :value="choice.value?choice.value:choice.name"
                                    :selected="choice.isSelected">{{ choice.text || choice.name }}
                            </option>
                        </select>
                        <select v-else-if="field.type=='multiselect'" multiple v-model="formData[inputName(field.id)]"
                                @change="clearError(field.id)"
                                @focus="inputFocus(key)"
                                @blur="inputBlur(key)" class="form__input">
                            <option v-for="choice in field.choices" :key='choice.value?choice.value:choice.name'
                                    :value="choice.value?choice.value:choice.name"
                                    :selected="choice.isSelected">{{ choice.text || choice.name }}
                            </option>
                        </select>
                        <textarea v-else-if="field.type=='textarea'" v-model="formData[inputName(field.id)]"
                                  @change="clearError(field.id)"
                                  @focus="inputFocus(key)"
                                  @blur="inputBlur(key)" @keydown.stop class="form__input"></textarea>
                        <input v-else :type="field.type" :name="'input_'+field.id"
                               v-model="formData[inputName(field.id)]"
                               @change="clearError(field.id)"
                               @focus="inputFocus(key)" @blur="inputBlur(key)" @keydown.stop class="form__input"
                        />

                        <label v-if="field.type!='hidden'" class="form__label"
                               :for="'input_'+field.id">{{ field.label }}</label>
                    </template>
                    <span v-if="validation_messages[field.id]"
                          class="form__field-error-message">{{ validation_messages[field.id] }}</span>
                </div>
                <div class="form__footer">
                    <button v-if="form.button" type="submit" class="form__submit"
                            :class="{'form__submit--loading':loading}">
                        {{ form.button.text }}
                        <span class="form__loader" v-show="loading">
                        <icon-spinner></icon-spinner>
                    </span>
                    </button>
                    <slot/>
                </div>

            </form>
        </transition>
        <transition name="slide">
            <div v-if="error" class="form__message form__message--error" key="error">The form could not be
                submitted
            </div>
        </transition>


    </div>
</template>

<script>
import actions from "../modules/fetchAxios";
import LinkPrivacyReplace from "./LinkPrivacyReplace";

import(/* webpackChunkName: "Countries" */ '../countries.json').then((module) => {
    return countries = module.default
})

var countries = [];

export default {
    name: "contact-form",
    components: {LinkPrivacyReplace},
    props: ['form'],
    data() {
        return {
            fields: [],
            valid: true,
            validation_message: {},
            validation_messages: {},
            active_field: null,
            loading: false,
            error: false,
            success: false,
            formData: {},
        }
    },

    computed: {
        countries() {
            return countries
        },
    },

    watch: {
        form: {
            handler: 'watchForm',
            immediate: true
        }
    },

    beforeUpdate() {
    },

    methods: {
        inputID($id) {
            return $id.toString().replace('.', '_')
        },
        inputName($id) {
            return 'input_' + $id.toString().replace('.', '_')
        },
        inputErrorMessage($field) {
            if (!$field.isRequired) {
                return false
            }
            if (this.validation_message[$field.id]) {
                this.validation_messages[$field.id] = this.validation_message[$field.id]
                return this.validation_messages[$field.id]
            }

            if ($field.isRequired && !this.formData[this.inputName($field.id)]) {
                let complex_id = $field.id.toString().split('.')
                if (complex_id.length > 1) {
                    this.validation_messages[$field.id] = this.validation_message[complex_id[0]]
                    return this.validation_messages[complex_id[0]]
                }
            }

            return false
        },

        inputFocus(key) {
            this.active_field = key
        },

        inputBlur(key) {
            this.active_field = null
        },

        clearError(id) {
            delete this.validation_messages[id]
        },

        formValidate() {
            let valid = true
            this.fields.map((field) => {
                if (field.isRequired && field.type == 'checkbox') {
                    let checked = false
                    field.inputs.map(input => {
                        if (this.formData[this.inputName(input.id)]) {
                            checked = true
                        }
                    })

                    if (!checked) {
                        this.validation_messages[field.id] = field.errorMessage || this.form.defaultErrorMessage
                        valid = false
                    }
                } else if (field.isRequired && (!this.formData[this.inputName(field.id)] || !this.formData[this.inputName(field.id)].length)) {
                    valid = false
                    this.validation_messages[field.id] = field.errorMessage || this.form.defaultErrorMessage
                }
            })

            this.valid = valid

            return this.valid
        },

        formSubmit() {
            if (this.loading) {
                return
            }

            this.loading = true

            //this.formValidate() //TODO check form before submission
            if (!this.valid) {
                this.loading = false
                return;

            }
            this.validation_messages = {}

            actions.submitForm(this.form.id, this.formData)
                .then((res) => {
                    if (res.data) {
                        this.valid = res.data.is_valid
                        this.error = false
                        if (res.data.validation_messages) {
                            this.validation_message = res.data.validation_messages
                        } else {
                            this.validation_message = {}
                        }
                    }
                    if (res.status === 200) {
                        this.success = true
                    }
                    if (res.error) {
                        this.error = true
                    }
                })
                .catch((error) => {
                    this.error = true
                })
                .finally(() => {
                    if (Object.keys(this.validation_message).length) {
                        for (let i = 0; i < this.fields.length; i++) {
                            this.inputErrorMessage(this.fields[i])
                        }
                    }
                    this.loading = false
                })
        },

        watchForm() {
            if (!this.form || !this.form.fields)
                return

            this.fields = []

            this.formData['is_submit_' + this.form.id] = 1
            let advanced_fields = ['address', 'name']
            for (let i = 0; i < this.form.fields.length; i++) {
                let field = this.form.fields[i]
                if (field.cssClass == 'country') {
                    field.choices = this.countries
                    // field.defaultValue = field.defaultCountry
                }
                if (advanced_fields.indexOf(field.type) >= 0) {
                    for (let k = 0; k < field.inputs.length; k++) {
                        let input = field.inputs[k], the_input, input_id
                        if (input.isHidden) {
                            continue
                        }
                        input_id = input.id.split('.')[1]
                        the_input = input
                        the_input.isRequired = field.isRequired
                        if (field.type == 'address' && input_id == '6') {
                            the_input.type = 'select'
                            the_input.choices = this.countries
                            the_input.defaultValue = field.defaultCountry
                        }
                        this.fields.push(the_input)
                    }

                } else {
                    this.fields.push(field)
                }
            }

            this.fields.map((field) => {
                if (field.defaultValue && !this.formData[this.inputName(field.id)]) {
                    this.formData[this.inputName(field.id)] = field.defaultValue
                }
            })
        },


    }
}
</script>

<style scoped>

</style>
