<template>
    <component v-bind:is="processedHtml"></component>
</template>

<script>
import LinkPrivacy from "./LinkPrivacy";

export default {
    name: "LinkPrivacyReplace",
    components: {LinkPrivacy},
    props: {'html': String, 'className':String},
    computed: {
        processedHtml() {
            let html = this.html.replace(/%(.*)%/, '<link-privacy :class="className">$1</link-privacy>');

            return {
                template: '<span>' + html + '</span>',
                props:{
                    className:{
                        type:String,
                        default:()=>{return this.className}
                    }
                }
            }
        },
    }
}
</script>

<style scoped>

</style>