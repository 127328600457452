<template>
    <div class="modal" ref="container" tabindex="-1" @keyup.esc="close">
        <slot></slot>
    </div>
</template>

<script>
import {EventBus} from '../main'

export default {
    name: "baseOverlay",
    data() {
        return {
            shown: false
        }
    },
    mounted() {
        EventBus.$on('closeOverlay', (payload) => {
            this.close()
        })
    },
    methods: {
        close() {
            this.$emit('close')
        },
        afterEnter() {
            this.shown = true
            this.$refs.container.focus() //todo: if no mobile
            this.$emit('loaded')
            EventBus.$emit('overlayOpened')
        },
        beforeLeave() {
            this.shown = false
            this.$emit('closed')
        },
        afterLeave() {
            EventBus.$emit('overlayClosed')
        },
    }
}
</script>

<style scoped>

</style>
